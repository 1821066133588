<script setup lang="ts">
const props = defineProps({
  items: {
    type: Array as PropType<string[]>,
    required: true
  }
})
const openStates = reactive({})

watch(() => props.items, (oldItems) => {
  if (oldItems) {
    for (const item of oldItems) {
      openStates[item.id] = false
    }
  }
}, { immediate: true })

const openItemId = ref<number | null>(null)

const toggleAccordion = (id: number) => {
  openItemId.value = openItemId.value === id ? null : id
}
</script>
<template>
  <div class="accordion" data-test="accordion">
    <div v-for="item in items" :key="item.id" class="accordion-item">
      <div class="accordion-header" @click="toggleAccordion(item.id)">
        <h4>
          {{ item.header }}
        </h4>
        <span class="accordion-icon" :class="{ 'is-open': openItemId === item.id }">
          <svg width="32" height="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
          </svg>
        </span>
      </div>
      <transition name="accordion">
        <div v-if="openItemId === item.id" class="accordion-content">
          <p>
            {{ item.content }}
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>
<style lang="postcss" scoped>
@import "@/assets/css/typography.pcss";
.accordion-item,
.accordion-header,
.accordion-content {
  max-width: 100%;
  text-align: left;
}

.accordion {
  width: 100%;
  display: grid;
  gap: 1rem;
}

.accordion-enter-active,
.accordion-leave-active {
  transition: max-height 0.3s, opacity 0.3s;
  max-height: 1000px;
  opacity: 1;
}

.accordion-enter-from,
.accordion-leave-to {
  max-height: 0;
  opacity: 0;
}

.accordion-header {
  padding: 1rem;
  background-color: var(--color-slate-50);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header h4 {
  margin: 0;
  @mixin typeface-summary;
  font-weight: 500;
  color: var(--color-slate-600);
}

.accordion-content {
  padding: 1rem;
  line-height: 1.5;
}

.accordion-icon {
  transform: rotate(180deg);
  transition: transform 0.3s;
  display: inline-block;
}

.accordion-icon.is-open {
  transform: rotate(0deg);
}

.accordion-icon svg {
  fill: var(--color-rose-300);

}

</style>
